import React, { useState , useEffect, useRef } from 'react';
import { useLocation,Link } from 'react-router-dom';
import Highlights from './Highlights';

const Header = () => {
     const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
    const marqueeRef = useRef(null);

    const handleMouseOver = () => {
      marqueeRef.current.stop();
    };
  
    const handleMouseOut = () => {
      marqueeRef.current.start();
    };
    // const MarqueeComponent = ({ children }) => {
       
      
    //     const handleMouseOver = () => {
    //       setIsHovered(true);
    //     };
      
    //     const handleMouseOut = () => {
    //       setIsHovered(false);
    //     };
      
      
    //   };

    const [isOpen, setIsOpen] = useState(window.innerWidth >= 991);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth >= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        
        <div className="header ">
            <div className="highlight-blk">
                <div className="container-fluid pd-0">
                    <div className="row">
                        <div className='col-md-2 highlight-tab'>
                            <p>Announcements <img src="https://webapi.entab.info/api/image/STHSM/public/Images/announcement.gif" className='announcement' /></p>
                        </div>
                        <div className='col-md-8'>
                        
<Highlights/>

                        </div>
                        <div className='col-md-2 highlight-tab'>
                            <p className="d-flex space-even">
                                <a href="https://twitter.com/stthomasschool_?lang=en" target="_blank"><img src='https://webapi.entab.info/api/image/STHSM/public/Images/twitter.png'></img></a>
                                <a href="https://www.instagram.com/st.thomasschool.mainpuri/" target="_blank"><img src='https://webapi.entab.info/api/image/STHSM/public/Images/instagram.png'></img></a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src='https://webapi.entab.info/api/image/STHSM/public/Images/business.png'></img></a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-blk">
                <div className='container-fluid'>
                    <div className='row'>
                    <div className='col-lg-4 col-md-4'>
                            <div className="header-contact desk-only"> 
                                <div><p className="border-tab"><img src="https://webapi.entab.info/api/image/STHSM/public/Images/whatsapp.png"></img> <a href="https://wa.me/7536040116">+91 7536040116</a></p></div>
                                <div> <p className="border-tab"><img src="https://webapi.entab.info/api/image/STHSM/public/Images/call.png"></img> <a href="tel: +91 9557352271">+91 9557352271</a></p></div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className='logo'>
                                <img src="https://webapi.entab.info/api/image/STHSM/public/Images/Logo.png" className='img-fluid'></img>
                            </div>
                        </div>                        
                        <div className='col-lg-4 col-md-4'>
                            <div className="header-contact"> 
                            <div className=' ph-only'><p className="border-tab"><img src="https://webapi.entab.info/api/image/STHSM/public/Images/whatsapp.png"></img> +91 7536040116</p></div>
                                <div className=' ph-only'> <p className="border-tab "><img src="https://webapi.entab.info/api/image/STHSM/public/Images/call.png"></img> +91 9557352271</p></div>
                                {/* <div><p className="border-tab"><a href="mailto: ssthomassrschool@gmail.com">ssthomassrschool@gmail.com</a></p></div> */}
                                <div><a href='https://entab.online/stsmup' target='_blank'><p className="border-tab">Parent Login</p></a></div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="menu">
                <button className="menu-toggle" onClick={toggleMenu}>
                <i className="bi bi-list"></i>
                </button>
                {isOpen && (
                    <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">
                        <li><Link to='/'><i className="bi bi-house-door-fill"></i></Link></li>
                        <li>About <i className="bi bi-chevron-down"></i>   
                            <ul className="sub-submenu">
                                <li><Link to='/about-us'>About Us</Link></li>
                                {/* <li>Administration</li> */}
                                <li><Link to='/patrons'>Our Patrons</Link></li>
                                {/* <li>Faculty</li>
                                <li>Our Mission & Vision</li> */}
                                <li><Link to="/principal-message">Principal's Message</Link></li> 
                                <li><Link to="/school-information">School Information</Link></li>

                                {/* 
                                <li>Aims & Objectives</li> */}
                                <li><Link to="/activities">Activities</Link></li>
                            </ul>
                        </li>

                        <li>
                            Academics <i className="bi bi-chevron-down"></i>
                            <ul className="sub-submenu">
                                <li><Link to='/syllabus'>Syllabus</Link></li> 
                                <li><Link to='/timings'>School Timings</Link></li>
                                {/* <li>Result</li>
                                <li>curriculum</li>
                                <li>Academic Calender</li> */}
                            </ul>
                        </li> 

                        <li>
                            Admission 
                             <i className="bi bi-chevron-down"></i> 
                            <ul className="sub-submenu">
                                <li><Link to="/admission-withdrawal">Admission and Withdrawal</Link></li> 
                            </ul> 
                        </li> 
                        <li>
                            Student Corner <i className="bi bi-chevron-down"></i>
                            <ul className="sub-submenu">
                                <li><Link to='/primary-wing'>Primary Wing </Link></li>
                                {/* <li>Senior Wing</li>
                                <li>Student Clubs</li> */}
                                <li><Link to="/clubs-association">Clubs and Association</Link></li> 
                                <li><Link to="/house">School Houses</Link></li> 
                                {/* <li>Inter House Competition</li> */}
                            </ul>
                        </li>
                        <li>
                            Facilities <i className="bi bi-chevron-down"></i>
                            <ul className="sub-submenu">
                               
                                <li><Link to="/laboratory">Laboratory</Link></li>
                                <li><Link to="/computer-lab">Computer Lab</Link></li>
                                <li><Link to="/library">Library</Link></li>
                                <li><Link to="/">F.M Studio</Link></li>
                            </ul>
                        </li> 
                        <li>
                            Gallery <i className="bi bi-chevron-down"></i>
                            <ul className="sub-submenu">
                                <li><Link to="/gallery">Photo Gallery</Link></li>
                                <li><Link to="/VideoGallery">Video Gallery</Link></li>
                            </ul>
                        </li> 
                        <li><Link to="/contactUs">Contact Us</Link></li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Header;