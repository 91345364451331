import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getTopper } from '../Service/Api';

const Topper = () => {


    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        items: 3,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 2,
            }, 
            1350: {
                items: 3,
            },
            1600: {
                items: 3,
            }
        }
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const topperData = await getTopper();
                setData(topperData);
            } catch (error) {
                console.error("Error fetching topper data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log("topper_data", data);
    }, [data]);

    const emptyArray = [
        { name: "Student Name", stream: "Subject", division: "100%", attachments: "https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png" },   
        { name: "Student Name", stream: "Subject", division: "100%", attachments: "https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png" },   
        { name: "Student Name", stream: "Subject", division: "100%", attachments: "https://webapi.entab.info/api/image/STHSM/public/Images/trophy.png" },   
      ];

      

    return (
        <div className="tpr-block">
            <div className='tpr-class-img'>
                <img src="https://webapi.entab.info/api/image/STHSM/public/Images/10.png" alt="Topper Class" />
            </div>
            <OwlCarousel className='owl-theme topper-theme' loop margin={10} {...settings}>
                {data.length > 0 ?
                    data.filter(item => item.class === "X").map((item, index) => (
                        <div className="item" key={index}>
                            <div className='topper-img'>
                                <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Topper" />
                            </div>
                            <div className='topper-cont'>
                                <p className='percent'>{item.division}</p>
                                <p>{item.name}</p>
                                <p>{item.stream}</p>
                            </div>
                        </div>
                    ))
                    :
                    emptyArray.map((data, index) => (
                        <div className="item" key={index}>
                            <div className='topper-img'>
                                <img src={data.attachments} alt="Default Topper" />
                            </div>
                            <div className='topper-cont'>
                                <p className='percent'>{data.division}</p>
                                <p>{data.name}</p>
                                <p>{data.stream}</p>
                            </div>
                        </div>
                    ))
                }
            </OwlCarousel>
        </div>
    );
};

export default Topper;
