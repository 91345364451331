
import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
const Library = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Library" mid="Facilities" breadcrumbName="Library" />
            <div className='container facilities_img'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Library</h3>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library2.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library2.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library3.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library3.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library4.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library4.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library5.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library5.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library6.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library6.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library7.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library7.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library8.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library8.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                     
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library9.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library9.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library10.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library10.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library11.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library11.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                     
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library12.jpg'>
                            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/library12.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                     

                </div>
                 
            </div>
            <Footer />
        </>
    )
}
export default Library