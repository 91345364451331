
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const AboutUs = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="About" mid="About" breadcrumbName="About Us" />
            <div className="inner-page">
                    <div className='container'>
                        <div className='row'>
                            
                            <div className='col-lg-6 col-md-12 animateMe'  data-animation="fadeInLeft">
                                <img src="https://webapi.entab.info/api/image/STHSM/public/Images/about-img.png" className='img-fluid' />
                            </div>
                            <div className='col-lg-6 col-md-12 animateMe'  data-animation="fadeInRight">
                                <p><span>St. Thomas School, Civil lines, Mainpuri</span> is a Catholic Christian,
minority educational institution, which was started in 1976
imparting education to all students irrespective of their sex, caste,
creed and religion to strengthen the inter religious, communal,
cultural and social ties among the students, so that they learn
to respect all religions and cultures in our country and strive
to maintain unity in diversity as part of God's plan for
humanity. It is an English medium co-educational school, affiliated
to the CISCE, Delhi and is being run by Christu Raj Education
Society, Mainpuri (registered)</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}
export default AboutUs