import React from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = () => {
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };

    return (
        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
                  <div className="item">
                      <img src='https://webapi.entab.info/api/image/STHSM/public/Images/slder1.jpg' />
                  </div> 
                  <div className="item">
                  <img src='https://webapi.entab.info/api/image/STHSM/public/Images/slder2.jpg' />
                </div> 
                <div className="item">
                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/slder3.jpg' />
            </div> 
            <div className="item">
            <img src='https://webapi.entab.info/api/image/STHSM/public/Images/slder4.jpg' />
          </div> 
          <div className="item">
          <img src='https://webapi.entab.info/api/image/STHSM/public/Images/slder5.jpg' />
        </div> 
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld1.jpg' />
            </div> 
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld5.jpg' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld-4.jpg' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld-9.jpg' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld-6.jpg' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld-7.jpg' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld-8.jpg' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld2.png' />
            </div>
            <div className="item">
              <img src='https://webapi.entab.info/api/image/STHSM/public/Images/sld3.jpg' />
            </div>
        </OwlCarousel>
    );
};

export default Events;