import React, { useEffect, useRef, useState } from 'react'
import { getHighlight } from '../Service/Api';
function Highlights() {
    const marqueeRef = useRef(null);
    const handleMouseOver = () => {
      marqueeRef.current.stop();
    };  
    const handleMouseOut = () => {
      marqueeRef.current.start();
    };
    const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);


  const emptyArray = [
    { description: "Stay tuned for latest updates" },   
    { description: "Stay tuned for latest updates" },
    { description: "Stay tuned for latest updates" },
  ];

  return (
 <marquee ref={marqueeRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
           <p>
  {data.length > 0 ? (
    data.map((item, index) => (
      <a
        href={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
        target="_blank"
        rel="noopener noreferrer"
        key={index}
      >
        {item.description}{" "}
        {item.attachments.length > 0 && (
          <i style={{ color: "white" }} className="bi bi-paperclip"></i>
        )}
      </a>
    ))
  ) : (
    emptyArray.map((data, index) => (
      <a key={index}>{data.description}</a>
    ))
  )}
</p>

    </marquee>
  )
}

export default Highlights