import { useEffect, useState } from "react";
import { getNews } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from "../components/Header"
import Breadcrumb from "../components/Breadcrumb";
const News = () => {

   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("All");
   const [selectedMonth, setSelectedMonth] = useState("All");
   const [monthOptions, setMonthOptions] = useState(["All"]);
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const fetchData = async () => {
         const galleryData = await getNews();
         setData(galleryData);
         const uniqueYears = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.fromDate) {
                  const year = new Date(item.fromDate).getFullYear();
                  return [year];
               } else {
                  return [];
               }
            }))
         );
         const uniqueYearRanges = uniqueYears.map(year => `${year}`);
         uniqueYearRanges.sort((a, b) => b - a);
         setYearRanges(uniqueYearRanges);

         const uniquemonth = Array.from(
            new Set(galleryData.flatMap((item) => {
               if (item.fromDate) {
                  const month = new Date(item.fromDate).getMonth() + 1;
                  return [month];
               } else {
                  return [];
               }
            }))
         );
         const uniqueMonths = uniquemonth.map(month => `${month}`);
         uniqueMonths.sort((a, b) => b - a);
         setMonthOptions(uniqueMonths);
      };
      fetchData();
   }, []);

   useEffect(() => {
      let filteredData = data;
      if (selectedYear !== "All") {
         filteredData = filteredData.filter((item) => {
            if (item.fromDate && item.fromDate.trim() !== "") {
               const year = new Date(item.fromDate).getFullYear();
               const month = new Date(item.fromDate).getMonth() + 1;
               return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
            } else {
               return false;
            }
         });
      }
      if (selectedMonth !== "All") {
         filteredData = filteredData.filter((item) => {
            if (item.fromDate && item.fromDate.trim() !== "") {
               const month = new Date(item.fromDate).getMonth() + 1;
               return parseInt(selectedMonth) === month;
            } else {
               return false;
            }
         });
      }
      if (searchQuery) {
         filteredData = filteredData.filter((item) =>
            item.title.toLowerCase().includes(searchQuery.toLowerCase())
         );
      }
      setFilteredData(filteredData);
   }, [selectedYear, selectedMonth, searchQuery, data]);

   return (
      <>
         <Header />
         <Breadcrumb pageTitle="News" mid="News" breadcrumbName="Latest News" />

         <section className="inner-page">
            <div className="container">

               <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="count-val">
                        <p>Total Count:{filterData.length} </p>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection">
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="month-selection">
                        <select
                           value={selectedMonth}
                           onChange={(e) => setSelectedMonth(e.target.value)}
                           className="monthSelect">
                           <option value={"All"}>All</option>
                           {monthOptions.map((month, index) => (
                              <option key={index} value={month}>{month}</option>
                           ))}
                        </select>
                     </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row years year2023 showYear">

               {filterData.length > 0 ? (
                                filterData.map((item, index) => (
                        <div className="col-md-6 newsCount">
                           <div className="inr-news-blk">
                              <h6 className="date">{item.date}</h6>
                              <div className="inr-news-content">
                                 <h3>{item.title}</h3>
                                 <p>{item.description}</p>
                                 <h6 className="loc">
                                    <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"> <span> View Attachment <i className="bi bi-arrow-right"></i> </span> </Link>
                                 </h6>
                              </div>
                           </div>
                        </div>
                     
                      ))
                         ) : (
                           <div className="col-md-12 newsCount">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                         )}

                  {/* <div className="col-md-12 newsCount" data-month="1">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                  
                    <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                    
                    <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
    
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                  <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                    <div className="load-more"><div className="next">Show More</div></div>
                    </div>
                <div className="row years year2022">
                     <div className="col-md-12 newsCount" data-month="1">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                     <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                   <div className="col-md-12 newsCount" data-month="2">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div>
                     <div className="col-md-12 newsCount" data-month="1">
                       <Link to="/" target="_blank">
                      <div className="inr-news-blk">
                         <h6 className="date">DD MM YYYY</h6>
                         <div className="inr-news-content">
                            <h3>News Heading</h3>
                            <p>Stay connected for more updates!</p>
                            <h6 className="loc"> 
                               <span> View Attachment <i className="bi bi-arrow-right"></i> </span>
                            </h6>
                         </div>
                      </div>
                  </Link>
                 </div> 
    
                    <div className="load-more"><div className="next">Show More</div></div>
                </div> */}
               </div>
            </div>
         </section>
      </>
   )
}

export default News