
import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
const Laboratory = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Laboratory" breadcrumbName="Laboratory" />
            <div className='container facilities_img'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Physics</h3>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy1.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy2.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy3.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>

                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy4.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>

                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy5.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy6.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
        
       
        
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>

                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/phy9.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Chemistry </h3>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/chem1.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/chem2.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/chem3.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/chem4.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Biology </h3>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology1.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology2.jpg' className='img-fluid' />
                            </a>

                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology3.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology4.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology5.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology6.jpg' className='img-fluid' /></a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology7.jpg' className='img-fluid' /></a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology8.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                            <a data-magnify="gallery" data-src="" data-group="a" href='https://webapi.entab.info/api/image/STHSM/public/Images/library1.jpg'>
                                <img src='https://webapi.entab.info/api/image/STHSM/public/Images/biology9.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Laboratory
