import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNotification } from '../Service/Api';

const Notification = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const datas = await getNotification();
      console.log(datas);
      setData(datas);
      if (datas.length > 0) {
        setShowModal(true);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModalToggle");
    if (modalElement && showModal) {
      // Initialize modal using Bootstrap's JavaScript
      const modal = new window.bootstrap.Modal(modalElement);

      // Add event listener for modal hide event
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

      // Show the modal
      modal.show();

      // Cleanup: Remove event listener when component unmounts
      return () => {
        if (modalElement) {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        }
      };
    }
  }, [data, showModal]);

  const handleModalHidden = () => {
    const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
    if (modalBackdrop) {
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
    setShowModal(false);
  };

  return (
    <>
      {data?.length > 0 && (
        <div
          className="popup modal fade"
          id="exampleModalToggle"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div id="modalH" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  {data.map((item, index) => (
                    <div key={index} className={`carousel-item${index === 0 ? ' active' : ''}`}>
                      {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                      <div className="modal-body">
                        <div className="card modalcard">
                          <div className="row">
                            <div className="card-body">
                              <h1>{item.title}</h1>
                              <img
                                src={`https://webapi.entab.info/api/image/${item.image[0]}`}
                                className="img-fluid"
                                alt="Lance Fort International School"
                              />
                              <p>{item.description}</p>
                              <div className="clearfix"></div>
                              <div className="modal-bottom">
                                <Link
                                  target="_blank"
                                  to={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                                  className="btn btn-primary btn-sm"
                                >
                                  Attachment
                                </Link>
                                <Link target="_blank" to={item?.url} className="btn btn-primary btn-sm">
                                  Click Here
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#modalH"
                  data-bs-slide="prev"
                >
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#modalH"
                  data-bs-slide="next"
                >
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
