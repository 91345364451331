import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { getSubGallery } from "../Service/Api";
import { SlideshowLightbox } from 'lightbox.js-react';
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";

const SubGallery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");
    const [data, setData] = useState(null); // For storing the data
    const [index, setIndex] = useState(null); // Initialize index state for tracking selected image

    useEffect(() => {
        const fetchData = async () => {
            try {
                const subgalleryData = await getSubGallery(id);
                setData(subgalleryData);
            } catch (error) {
                console.error(error);
                navigate("/error"); // Navigate to error page or handle error as needed
            }
        };
        fetchData();
    }, [id]); // Fetch data when id changes

    if (!data) { // Check if data is not yet available
        return <div>Loading...</div>;
    }

    const slides = data.attachments?.map(img => ({
        src: `https://webapi.entab.info/api/image/${img}`
    }));

    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Sub Gallery" mid="Gallery" breadcrumbName="Sub Gallery" />
            <section className="inner-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row tabs-dynamic">
                                <div className="col-xxl-3 col-xl-3 col-md-3 col-sm-6 col-12">
                                    <div className="shrink"><Link to="/Gallery"><i className="bi bi-arrow-left"></i> Back to Gallery</Link></div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-md-3 col-12">
                                    <div><b>Event Date:</b> {new Date(data.date).toLocaleDateString("en-GB")}</div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-md-3 col-sm-6 col-12">
                                    <div><b>Updated On:</b> {new Date(data.updatedOn).toLocaleDateString("en-GB")}</div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-md-3 col-sm-6 col-12">
                                    <div><b>Total Photos:</b> {data.attachments?.length}</div>
                                </div>
                            </div>
                            <div className="fulwidth">
                                <div className="description">
                                    <h3 className="subhead">{data.title}</h3>
                                    <p><b>Description:</b> {data.description}</p>
                                </div>
                            </div>
                            <div className="row zoompic">
                                {data.attachments.map((img, index) => (
                                    <div className="col-md-4 col-sm-6 mt-20" key={index}>
                                         <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}>
                                        <img src={`https://webapi.entab.info/api/image/${img}`}   alt={data.title} className="img-fluid" />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>




                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SubGallery;
